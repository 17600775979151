// extracted by mini-css-extract-plugin
export var Embla = "index-module--Embla--tDsek";
export var EmblaButton = "index-module--EmblaButton--1T21o";
export var EmblaButton__Next = "index-module--EmblaButton__Next--vsLLx";
export var EmblaButton__Prev = "index-module--EmblaButton__Prev--8R8T4";
export var Embla_Container = "index-module--Embla_Container--rgRsI";
export var Embla_Slide = "index-module--Embla_Slide--de3fC";
export var Embla_Slide__Inner = "index-module--Embla_Slide__Inner--dyhSC";
export var Embla_Viewport = "index-module--Embla_Viewport--DmfKb";
export var embla__button__svg = "index-module--embla__button__svg--4EQxb";
export var embla__slide__img = "index-module--embla__slide__img--lSqrc";
export var embla__viewport = "index-module--embla__viewport--DrTpC";
export var isDraggable = "index-module--is-draggable--tdRcl";
export var isDragging = "index-module--is-dragging--RxGdv";
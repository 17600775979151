// extracted by mini-css-extract-plugin
export var Catch = "index-module--Catch--Q1WEH";
export var CatchEn = "index-module--CatchEn--aXpGn";
export var CatchEn_Bg = "index-module--CatchEn_Bg--fDRTx";
export var CatchEn_BgImage = "index-module--CatchEn_BgImage--RMKM7";
export var CatchEn_Contents = "index-module--CatchEn_Contents--b+Oaf";
export var CatchEn_ContentsWrapper = "index-module--CatchEn_ContentsWrapper--5ozyO";
export var CatchEn_Image__pc = "index-module--CatchEn_Image__pc--8GxWn";
export var CatchEn_Image__sp = "index-module--CatchEn_Image__sp--5OZ1L";
export var Catch_Image = "index-module--Catch_Image--3HRnn";
export var Catch_Image__pc = "index-module--Catch_Image__pc--3wVn9";
export var Catch_Image__sp = "index-module--Catch_Image__sp--DcCVZ";
export var Catch_Mainvisual = "index-module--Catch_Mainvisual--ZNtyA";
export var Catch_Net = "index-module--Catch_Net--ZxTQ1";
export var Catch_Slideitem = "index-module--Catch_Slideitem--6uWvo";
export var Catch__En = "index-module--Catch__En--j5xSJ";
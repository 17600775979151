import React, { FC } from "react";
import clsx from "clsx";
import * as styles from "./index.module.scss";

const svgBasicProps = {
  xmlns: "http://www.w3.org/2000/svg",
  xmlnsXlink: "http://www.w3.org/1999/xlink",
};

type Props = {
  direction: "Up" | "Down" | "Right" | "Left";
};

export const CircleArrowIcon: FC<Props> = ({ direction }) => {
  return (
    <div
      className={clsx(
        styles.CircleArrowIcon,
        styles[`CircleArrowIcon__${direction}`],
      )}
      style={{ color: "rgb(178, 178, 178)" }}
    >
      <svg {...svgBasicProps} width="100%" height="100%" viewBox="-1 -4 15 15">
        <path
          d="M1 1.5L6.5 7.5L12.5 1.5"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

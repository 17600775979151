import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Language } from "~context/LanguageContext";
import { OptionalGatsbyImage } from "~components/utils/optional-gatsby-image";

import * as styles from "./index.module.scss";

type Props = {
  language: Language;
};

export const Achievements: React.FC<props> = (props) => {
  const data: GatsbyHomeAchievementsComponentQuery = useStaticQuery(graphql`
    query HomeAchievementsComponentQuery {
      athome: file(
        relativePath: { eq: "kenro/achievement/athome.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      blackbear: file(
        relativePath: { eq: "achievements/blackbear.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      cyberagent: file(
        relativePath: { eq: "kenro/achievement/cyberagent.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      cygames: file(
        relativePath: { eq: "kenro/achievement/cygames.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      doublejumptokyo: file(
        relativePath: { eq: "achievements/double_jump_tokyo.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      enjapan: file(
        relativePath: { eq: "achievements/enjapan.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      eureka: file(
        relativePath: { eq: "kenro/achievement/eureka.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      finatext: file(
        relativePath: { eq: "achievements/finatext.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      freee: file(
        relativePath: { eq: "achievements/freee.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      genomelink: file(
        relativePath: { eq: "achievements/genomelink.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      glory: file(
        relativePath: { eq: "achievements/glory.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      goodpatch: file(
        relativePath: { eq: "achievements/goodpatch.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      gmo_pepabo: file(
        relativePath: { eq: "kenro/achievement/gmo_pepabo.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      kakehashi: file(
        relativePath: { eq: "achievements/kakehashi.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      kanmu: file(
        relativePath: { eq: "achievements/kanmu.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      legalforce: file(
        relativePath: { eq: "kenro/achievement/legalforce.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      layerx: file(
        relativePath: { eq: "achievements/layerx.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      mdm: file(
        relativePath: { eq: "achievements/mdm.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      mizuho_rt: file(
        relativePath: { eq: "achievements/mizuho_rt.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      mdis: file(
        relativePath: { eq: "kenro/achievement/mdis.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      nttdata: file(
        relativePath: { eq: "kenro/achievement/nttdata.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      nikkei: file(
        relativePath: { eq: "kenro/achievement/nikkei.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      onecareer: file(
        relativePath: { eq: "achievements/onecareer.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      pfn: file(
        relativePath: { eq: "achievements/pfn.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      pixiv: file(
        relativePath: { eq: "kenro/achievement/pixiv.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      sakura_internet: file(
        relativePath: { eq: "kenro/achievement/sakura_internet.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      sbi: file(
        relativePath: { eq: "achievements/sbi_digitrust.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      shogakukan: file(
        relativePath: { eq: "achievements/shogakukan.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      smarthr: file(
        relativePath: { eq: "achievements/smarthr.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      stores: file(
        relativePath: { eq: "achievements/stores.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      tier_iv: file(
        relativePath: { eq: "achievements/tier_iv.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      trendmicro: file(
        relativePath: { eq: "achievements/trendmicro.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      tokyogas: file(
        relativePath: { eq: "achievements/tokyogas.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      ubie: file(
        relativePath: { eq: "achievements/ubie.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      wantedly: file(
        relativePath: { eq: "kenro/achievement/wantedly.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);
  const { language } = props;

  return (
    <div className={styles.Achievements}>
      {language === "ja" && (
        <>
          <div className={styles.Achievement_Grid}>
            <a href="https://corp.en-japan.com/">
              <div className={styles.Achievement_Item}>
                <OptionalGatsbyImage
                  className={styles.Achievement_Image}
                  data={data.enjapan}
                  alt="エン・ジャパン"
                />
              </div>
            </a>
            <a href="https://www.cyberagent.co.jp/">
              <div className={styles.Achievement_Item}>
                <OptionalGatsbyImage
                  className={styles.Achievement_Image}
                  data={data.cyberagent}
                  alt="サイバーエージェント"
                />
              </div>
            </a>
            <a href="https://www.shogakukan.co.jp/">
              <div className={styles.Achievement_Item}>
                <OptionalGatsbyImage
                  className={styles.Achievement_Image}
                  data={data.shogakukan}
                  alt="小学館"
                />
              </div>
            </a>
            <a href="https://www.tokyo-gas.co.jp/">
              <div className={styles.Achievement_Item}>
                <OptionalGatsbyImage
                  className={styles.Achievement_Image}
                  data={data.tokyogas}
                  alt="東京ガス"
                />
              </div>
            </a>
            <a href="https://www.trendmicro.com/">
              <div className={styles.Achievement_Item}>
                <OptionalGatsbyImage
                  className={styles.Achievement_Image}
                  data={data.trendmicro}
                  alt="トレンドマイクロ"
                />
              </div>
            </a>
            <a href="https://www.freee.co.jp/">
              <div className={styles.Achievement_Item}>
                <OptionalGatsbyImage
                  className={styles.Achievement_Image}
                  data={data.freee}
                  alt="freee"
                />
              </div>
            </a>
            <a href="https://athome-inc.jp/">
              <div className={styles.Achievement_Item}>
                <OptionalGatsbyImage
                  className={styles.Achievement_Image}
                  data={data.athome}
                  alt="アットホーム"
                />
              </div>
            </a>
            <a href="https://wantedlyinc.com/ja">
              <div className={styles.Achievement_Item}>
                <OptionalGatsbyImage
                  className={styles.Achievement_Image}
                  data={data.wantedly}
                  alt="ウォンテッドリー"
                />
              </div>
            </a>
            <a href="https://eure.jp/">
              <div className={styles.Achievement_Item}>
                <OptionalGatsbyImage
                  className={styles.Achievement_Image}
                  data={data.eureka}
                  alt="エウレカ"
                />
              </div>
            </a>
            <a href="https://sbidigitrust.com/">
              <div className={styles.Achievement_Item}>
                <OptionalGatsbyImage
                  className={styles.Achievement_Image}
                  data={data.sbi}
                  alt="SBIデジトラスト"
                />
              </div>
            </a>
            <a href="https://www.nttdata-univ.co.jp/">
              <div className={styles.Achievement_Item}>
                <OptionalGatsbyImage
                  className={styles.Achievement_Image}
                  data={data.nttdata}
                  alt="NTTデータ ユニバーシティ"
                />
              </div>
            </a>
            <a href="https://www.kakehashi.life/">
              <div className={styles.Achievement_Item}>
                <OptionalGatsbyImage
                  className={styles.Achievement_Image}
                  data={data.kakehashi}
                  alt="カケハシ"
                />
              </div>
            </a>
            <a href="https://kanmu.co.jp/">
              <div className={styles.Achievement_Item}>
                <OptionalGatsbyImage
                  className={styles.Achievement_Image}
                  data={data.kanmu}
                  alt="kanmu"
                />
              </div>
            </a>
            <a href="https://www.glory.co.jp/">
              <div className={styles.Achievement_Item}>
                <OptionalGatsbyImage
                  className={styles.Achievement_Image}
                  data={data.glory}
                  alt="グローリー"
                />
              </div>
            </a>
            <a href="https://www.cygames.co.jp/">
              <div className={styles.Achievement_Item}>
                <OptionalGatsbyImage
                  className={styles.Achievement_Image}
                  data={data.cygames}
                  alt="Cygames"
                />
              </div>
            </a>
            <a href="https://www.sakura.ad.jp/corporate/">
              <div className={styles.Achievement_Item}>
                <OptionalGatsbyImage
                  className={styles.Achievement_Image}
                  data={data.sakura_internet}
                  alt="さくらインターネット"
                />
              </div>
            </a>
            <a href="https://pepabo.com/">
              <div className={styles.Achievement_Item}>
                <OptionalGatsbyImage
                  className={styles.Achievement_Image}
                  data={data.gmo_pepabo}
                  alt="GMOペパボ"
                />
              </div>
            </a>
            <a href="https://www.st.inc/">
              <div className={styles.Achievement_Item}>
                <OptionalGatsbyImage
                  className={styles.Achievement_Image}
                  data={data.stores}
                  alt="STORES"
                />
              </div>
            </a>
            <a href="https://smarthr.co.jp/">
              <div className={styles.Achievement_Item}>
                <OptionalGatsbyImage
                  className={styles.Achievement_Image}
                  data={data.smarthr}
                  alt="SmartHR"
                />
              </div>
            </a>
            <a href="https://www.doublejump.tokyo/">
              <div className={styles.Achievement_Item}>
                <OptionalGatsbyImage
                  className={styles.Achievement_Image}
                  data={data.doublejumptokyo}
                  alt="double jump.tokyo"
                />
              </div>
            </a>
            <a href="https://www.nikkei.co.jp/nikkeiinfo/">
              <div className={styles.Achievement_Item}>
                <OptionalGatsbyImage
                  className={styles.Achievement_Image}
                  data={data.nikkei}
                  alt="日本経済新聞社"
                />
              </div>
            </a>
            <a href="https://www.pixiv.co.jp/">
              <div className={styles.Achievement_Item}>
                <OptionalGatsbyImage
                  className={styles.Achievement_Image}
                  data={data.pixiv}
                  alt="ピクシブ"
                />
              </div>
            </a>
            <a href="https://finatext.com/">
              <div className={styles.Achievement_Item}>
                <OptionalGatsbyImage
                  className={styles.Achievement_Image}
                  data={data.finatext}
                  alt="Finatextホールディングス"
                />
              </div>
            </a>
            <a href="https://www.preferred.jp/ja/">
              <div className={styles.Achievement_Item}>
                <OptionalGatsbyImage
                  className={styles.Achievement_Image}
                  data={data.pfn}
                  alt="Preferred Networks"
                />
              </div>
            </a>
            <a href="https://www.mizuho-rt.co.jp/index.html">
              <div className={styles.Achievement_Item}>
                <OptionalGatsbyImage
                  className={styles.Achievement_Image}
                  data={data.mizuho_rt}
                  alt="みずほリサーチ&テクノロジーズ"
                />
              </div>
            </a>
            <a href="https://corp.mitsui-x.com/">
              <div className={styles.Achievement_Item}>
                <OptionalGatsbyImage
                  className={styles.Achievement_Image}
                  data={data.mdm}
                  alt="三井物産デジタル・アセットマネジメント"
                />
              </div>
            </a>
            <a href="https://ubie.life/">
              <div className={styles.Achievement_Item}>
                <OptionalGatsbyImage
                  className={styles.Achievement_Image}
                  data={data.ubie}
                  alt="ユビー"
                />
              </div>
            </a>
            <a href="https://legalforce-corp.com/">
              <div className={styles.Achievement_Item}>
                <OptionalGatsbyImage
                  className={styles.Achievement_Image}
                  data={data.legalforce}
                  alt="LegalForce"
                />
              </div>
            </a>
            <a href="https://layerx.co.jp/">
              <div className={styles.Achievement_Item}>
                <OptionalGatsbyImage
                  className={styles.Achievement_Image}
                  data={data.layerx}
                  alt="LayerX"
                />
              </div>
            </a>
            <a href="https://onecareer.co.jp/">
              <div className={styles.Achievement_Item}>
                <OptionalGatsbyImage
                  className={styles.Achievement_Image}
                  data={data.onecareer}
                  alt="ワンキャリア"
                />
              </div>
            </a>
          </div>
        </>
      )}
      {language === "en" && (
        <>
          <div className={styles.Achievement_Grid}>
            <a href="https://www.mdis.co.jp/">
              <div className={styles.Achievement_Item}>
                <OptionalGatsbyImage
                  className={styles.Achievement_Image}
                  data={data.mdis}
                  alt="MITSUBISHI ELECTRIC INFORMATION SYSTEMS CORPORATION"
                />
              </div>
            </a>
            <a href="https://www.mizuho-rt.co.jp/index.html">
              <div className={styles.Achievement_Item}>
                <OptionalGatsbyImage
                  className={styles.Achievement_Image}
                  data={data.mizuho_rt}
                  alt="Mizuho Research & Technologies"
                />
              </div>
            </a>
            <a href="https://www.nttdata-univ.co.jp/">
              <div className={styles.Achievement_Item}>
                <OptionalGatsbyImage
                  className={styles.Achievement_Image}
                  data={data.nttdata}
                  alt="NTT DATA UNIVERSITY CORPORATION"
                />
              </div>
            </a>
            <a href="https://corp.mitsui-x.com/">
              <div className={styles.Achievement_Item}>
                <OptionalGatsbyImage
                  className={styles.Achievement_Image}
                  data={data.mdm}
                  alt="Mitsui & Co. Digital Asset Management"
                />
              </div>
            </a>
            <a href="https://www.trendmicro.com/">
              <div className={styles.Achievement_Item}>
                <OptionalGatsbyImage
                  className={styles.Achievement_Image}
                  data={data.trendmicro}
                  alt="Trend Micro"
                />
              </div>
            </a>
            <a href="https://blackbear-ics.com/">
              <div className={styles.Achievement_Item}>
                <OptionalGatsbyImage
                  className={styles.Achievement_Image}
                  data={data.blackbear}
                  alt="BlackBear"
                />
              </div>
            </a>
            <a href="https://www.preferred.jp/en/">
              <div className={styles.Achievement_Item}>
                <OptionalGatsbyImage
                  className={styles.Achievement_Image}
                  data={data.pfn}
                  alt="Preferred Networks"
                />
              </div>
            </a>
            <a href="https://www.cygames.co.jp/">
              <div className={styles.Achievement_Item}>
                <OptionalGatsbyImage
                  className={styles.Achievement_Image}
                  data={data.cygames}
                  alt="Cygames"
                />
              </div>
            </a>
            <a href="https://www.pixiv.co.jp/">
              <div className={styles.Achievement_Item}>
                <OptionalGatsbyImage
                  className={styles.Achievement_Image}
                  data={data.pixiv}
                  alt="pixiv"
                />
              </div>
            </a>
            <a href="https://tier4.jp/">
              <div className={styles.Achievement_Item}>
                <OptionalGatsbyImage
                  className={styles.Achievement_Image}
                  data={data.tier_iv}
                  alt="TIER IV"
                />
              </div>
            </a>
            <a href="https://genomelink.io/">
              <div className={styles.Achievement_Item}>
                <OptionalGatsbyImage
                  className={styles.Achievement_Image}
                  data={data.genomelink}
                  alt="Genomelink"
                />
              </div>
            </a>
            <a href="https://www.doublejump.tokyo/">
              <div className={styles.Achievement_Item}>
                <OptionalGatsbyImage
                  className={styles.Achievement_Image}
                  data={data.doublejumptokyo}
                  alt="double jump.tokyo"
                />
              </div>
            </a>
          </div>
        </>
      )}
    </div>
  );
};

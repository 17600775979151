import React, { useState, useCallback, useEffect } from "react";
import clsx from "clsx";
import { useEmblaCarousel } from "embla-carousel/react";
import { useInterval } from "~hooks/useInterval";
import { CircleArrowIcon } from "~components/utils/circle-arrow-icon";
import * as styles from "./index.module.scss";

type ButtonProps = {
  enabled: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

const PrevButton: React.FC<ButtonProps> = ({ enabled, onClick }) => (
  <button
    type="button"
    className={clsx(styles.EmblaButton, styles.EmblaButton__Prev)}
    onClick={onClick}
    disabled={!enabled}
  >
    <CircleArrowIcon direction="Left" />
  </button>
);

const NextButton: React.FC<ButtonProps> = ({ enabled, onClick }) => (
  <button
    type="button"
    className={clsx(styles.EmblaButton, styles.EmblaButton__Next)}
    onClick={onClick}
    disabled={!enabled}
  >
    <CircleArrowIcon direction="Right" />
  </button>
);

const useAutoPlay = (
  isAutoPlayEnabled = false,
  ...useIntervalProps: Parameters<typeof useInterval>
) => {
  const [playInterval, stopInterval] = useInterval(...useIntervalProps);

  const play = React.useCallback(() => {
    if (!isAutoPlayEnabled) return;

    playInterval();
  }, [playInterval, isAutoPlayEnabled]);

  const stop = React.useCallback(() => {
    if (!isAutoPlayEnabled) return;

    stopInterval();
  }, [stopInterval, isAutoPlayEnabled]);

  return [play, stop] as const;
};

type Slide = () => JSX.Element;

export type Slides = Slide[];

type Props = {
  slides: Slides;
  autoplay?: boolean;
  autoplaySpeed?: number;
  buttonEnabled?: boolean;
  marginBetweenSlide?: number;
};

const Component: React.FC<Required<Props>> = ({
  slides,
  autoplay,
  autoplaySpeed,
  buttonEnabled,
  marginBetweenSlide,
}) => {
  const [viewportRef, embla] = useEmblaCarousel({ loop: true });
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

  const autoplayCallback = React.useCallback(() => {
    if (!embla) return;

    if (embla.canScrollNext()) {
      embla.scrollNext();
    } else {
      embla.scrollTo(0);
    }
  }, [embla]);
  const [play, stop] = useAutoPlay(autoplay, autoplayCallback, autoplaySpeed);

  const scrollPrev = React.useCallback(() => {
    if (!embla) return;

    embla.scrollPrev();
    stop();
  }, [embla, stop]);

  const scrollNext = React.useCallback(() => {
    if (!embla) return;

    embla.scrollNext();
    stop();
  }, [embla, stop]);

  const onSelect = useCallback(() => {
    if (!embla) return;
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla]);

  useEffect(() => {
    if (!embla) return;

    onSelect();
    embla.on("select", onSelect);
    embla.on("pointerDown", stop);
    embla.on("settle", play);
  }, [embla, onSelect, stop, play]);

  useEffect(() => {
    play();
  }, [play]);

  return (
    <div className={styles.Embla}>
      <div className={styles.Embla_Viewport} ref={viewportRef}>
        <div
          className={styles.Embla_Container}
          style={
            marginBetweenSlide !== undefined
              ? {
                  marginLeft: `-${marginBetweenSlide}px`,
                }
              : undefined
          }
        >
          {slides.map((SlideComponent, index) => (
            <div
              key={`slide_${index}`}
              className={clsx(styles.Embla_Slide)}
              style={
                marginBetweenSlide !== undefined
                  ? {
                      paddingLeft: `${marginBetweenSlide}px`,
                    }
                  : undefined
              }
            >
              <div className={styles.Embla_Slide__Inner}>
                <SlideComponent />
              </div>
            </div>
          ))}
        </div>
      </div>
      {buttonEnabled && (
        <>
          <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
          <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
        </>
      )}
    </div>
  );
};

export const Carousel: React.FC<Props> = ({
  slides,
  autoplay,
  autoplaySpeed,
  buttonEnabled,
  marginBetweenSlide,
}) => {
  const defaultProps: Required<Props> = {
    slides,
    autoplay: autoplay ?? false,
    autoplaySpeed: autoplaySpeed ?? 4000,
    buttonEnabled: buttonEnabled ?? true,
    marginBetweenSlide: marginBetweenSlide ?? 10,
  };
  return <Component {...defaultProps} />;
};

import { useState, useEffect, useCallback, useRef } from "react";

const isClient = typeof window !== "undefined";

const getDate = () => {
  return isClient ? performance.now() : new Date().getTime();
};

export const useInterval = (callback: () => void, interval: number) => {
  const [isRunningState, setIsRunningState] = useState(false);
  const stop = useCallback(() => {
    setIsRunningState(false);
  }, [setIsRunningState]);
  const play = useCallback(() => {
    setIsRunningState(true);
  }, [setIsRunningState]);
  const startTimeRef = useRef(getDate());
  const savedCallbackRef = useRef(callback);
  const handlerRef = useRef<{ value: number }>({ value: 0 });

  const loop = useCallback(() => {
    handlerRef.current.value = requestAnimationFrame(loop);
    const current = getDate();
    const delta = current - startTimeRef.current;
    if (delta >= interval) {
      savedCallbackRef.current();
      startTimeRef.current = getDate();
    }
  }, [interval]);

  useEffect(() => {
    savedCallbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    let id = handlerRef.current.value;
    if (!isRunningState) {
      cancelAnimationFrame(id);
      return undefined;
    }

    startTimeRef.current = getDate();
    handlerRef.current.value = requestAnimationFrame(loop);

    return () => {
      id = handlerRef.current.value;
      cancelAnimationFrame(id);
      stop();
    };
  }, [isRunningState, interval, stop, loop]);

  return [play, stop] as const;
};

// extracted by mini-css-extract-plugin
export var Assessment_Card = "index-module--Assessment_Card--PaANn";
export var Assessment_CardTitle = "index-module--Assessment_CardTitle--5v+sB";
export var Assessment_List = "index-module--Assessment_List--x7BLs";
export var Product_Header = "index-module--Product_Header--W7tbG";
export var Product_Name = "index-module--Product_Name--LANU0";
export var Products = "index-module--Products--uABvq";
export var ProductsAchievements = "index-module--ProductsAchievements--dZv8Q";
export var ProductsAchievements_Description = "index-module--ProductsAchievements_Description--XCVL8";
export var ProductsAchievements_Heading = "index-module--ProductsAchievements_Heading--PklYO";
export var ProductsEn = "index-module--ProductsEn--7ZIX3";
export var ProductsEn_Contents = "index-module--ProductsEn_Contents--Zx2ro";
export var ProductsEn_Heading = "index-module--ProductsEn_Heading--WWdnr";
export var ProductsEn_Image = "index-module--ProductsEn_Image--mN2ry";
export var ProductsEn_List = "index-module--ProductsEn_List--9Ox2v";
export var ProductsEn_ListWrapper = "index-module--ProductsEn_ListWrapper--Ur39-";
export var ProductsList = "index-module--ProductsList--BQ8Tj";
export var ProductsList_AssessmentName = "index-module--ProductsList_AssessmentName--isMKO";
export var ProductsList_AssessmentPeople = "index-module--ProductsList_AssessmentPeople--xw6XN";
export var ProductsList_Button = "index-module--ProductsList_Button--n0iSy";
export var ProductsList_Description = "index-module--ProductsList_Description--KEj0Y";
export var ProductsList_Grid = "index-module--ProductsList_Grid--Rwpbe";
export var ProductsList_GridWrapper = "index-module--ProductsList_GridWrapper--b8Bfs";
export var ProductsList_KenroLogo = "index-module--ProductsList_KenroLogo--Og1C2";
export var ProductsList_KenroMockup = "index-module--ProductsList_KenroMockup--ZRdJu";
export var ProductsList_Link = "index-module--ProductsList_Link--T4XVi";
export var ProductsList_LinkWrapper = "index-module--ProductsList_LinkWrapper--3ppOQ";
export var ProductsList_Product = "index-module--ProductsList_Product--4comb";
export var Products_ButtonLink = "index-module--Products_ButtonLink--LPDvW";
export var Products_Card = "index-module--Products_Card--nJLrA";
export var Products_CardTitle = "index-module--Products_CardTitle--2A1e3";
export var Products_Layout = "index-module--Products_Layout--lfi98";
export var Products_Link = "index-module--Products_Link--VAMwL";
export var Products_Product = "index-module--Products_Product--ASKI9";
export var Shisho = "index-module--Shisho--loEbj";
export var ShishoDescription = "index-module--ShishoDescription--lCsuD";
export var ShishoFlex = "index-module--ShishoFlex--32ifi";
export var ShishoLogo = "index-module--ShishoLogo--Hw9Lr";
export var ShishoMockPc = "index-module--ShishoMockPc--9XxdM";
export var ShishoMockSp = "index-module--ShishoMockSp--B49ff";
export var ShishoMockWrapper = "index-module--ShishoMockWrapper--GAcFZ";
export var Solution = "index-module--Solution--nveI4";
export var Solution_List = "index-module--Solution_List--ZaMmL";
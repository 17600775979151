import React, { FC } from "react";
import clsx from "clsx";
import { useStaticQuery, graphql } from "gatsby";

import { Carousel } from "~components/utils/carousel";
import { Language } from "~context/LanguageContext";
import * as styles from "./index.module.scss";

import { OptionalGatsbyImage } from "~components/utils/optional-gatsby-image";

type Props = {
  language: Language;
};

export const Catch: FC<Props> = (props) => {
  const data: GatsbyHomeCatchComponentQuery = useStaticQuery(graphql`
    query HomeCatchComponent {
      catch_pc: file(relativePath: { eq: "catch_pc.svg" }) {
        publicURL
      }
      catch_sp: file(relativePath: { eq: "catch_sp.svg" }) {
        publicURL
      }
      catch_en: file(relativePath: { eq: "en/catch_en.jpg" }) {
        publicURL
      }
      catch_en_pc: file(relativePath: { eq: "catch_en_pc.svg" }) {
        publicURL
      }
      catch_en_sp: file(relativePath: { eq: "catch_en_sp.svg" }) {
        publicURL
      }
      background_net: file(relativePath: { eq: "top/bg_net.svg" }) {
        publicURL
      }
      catch1: file(relativePath: { eq: "top/catch1.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      catch2: file(relativePath: { eq: "top/catch2.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      catch3: file(relativePath: { eq: "top/catch3.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);
  const { language } = props;

  if (language === "en") {
    return (
      <section className={styles.CatchEn}>
        <div className={styles.CatchEn_Bg}>
          <img
            className={styles.CatchEn_BgImage}
            src={data.catch_en?.publicURL ?? ""}
            alt=""
          />
        </div>
        <div className={styles.CatchEn_ContentsWrapper}>
          <img
            src={data.catch_en_pc?.publicURL ?? ""}
            className={styles.CatchEn_Image__pc}
            alt="We are the backbone of engineers."
          />
          <img
            src={data.catch_en_sp?.publicURL ?? ""}
            className={styles.CatchEn_Image__sp}
            alt="We are the backbone of engineers."
          />
          <p className={styles.CatchEn_Contents}>
            Flatt Security, based in Tokyo, offers expert security assessments
            and penetration testing for software. Our seasoned professionals
            deliver proven, top-tier services. We also provide tools to help you
            internalize cutting edge, state of the art security practices.
          </p>
        </div>
      </section>
    );
  }

  return (
    <section className={styles.Catch}>
      <div className={styles.Catch_Mainvisual}>
        <Carousel
          slides={[
            () => (
              <OptionalGatsbyImage
                data={data.catch1}
                alt=""
                className={styles.Catch_Slideitem}
              />
            ),
            () => (
              <OptionalGatsbyImage
                data={data.catch2}
                alt=""
                className={styles.Catch_Slideitem}
              />
            ),
            () => (
              <OptionalGatsbyImage
                data={data.catch3}
                alt=""
                className={styles.Catch_Slideitem}
              />
            ),
          ]}
          autoplay
          autoplaySpeed={3000}
          buttonEnabled={false}
          marginBetweenSlide={0}
        />
      </div>
      <div
        className={styles.Catch_Net}
        style={{
          backgroundImage: `url(${data.background_net?.publicURL})`,
        }}
      />
      <img
        src={data.catch_pc?.publicURL ?? ""}
        className={clsx(styles.Catch_Image, styles.Catch_Image__pc)}
        alt=""
      />
      <img
        src={data.catch_sp?.publicURL ?? ""}
        className={clsx(styles.Catch_Image, styles.Catch_Image__sp)}
        alt=""
      />
    </section>
  );
};

import React, { FC } from "react";
import { useStaticQuery, Link, graphql } from "gatsby";
import { SectionLayout } from "~components/templates/section-layout";
import { Button } from "~components/utils/button";
import { Language } from "~context/LanguageContext";
import * as styles from "./index.module.scss";

type ContainerProps = {
  language: Language;
};

type Props = {
  data: Record<keyof GatsbyHomeProductsComponentQuery, any>;
} & ContainerProps;

const Component: FC<Props> = (props) => {
  const { language, data } = props;

  if (language === "en") {
    return (
      <div className={styles.ProductsEn}>
        <ul className={styles.ProductsEn_ListWrapper}>
          <li className={styles.ProductsEn_List}>
            <img
              className={styles.ProductsEn_Image}
              src={data?.service?.publicURL || ""}
              alt=""
            />
            <h2 className={styles.ProductsEn_Heading}>
              Security Assessments & Penetration Testing
            </h2>
            <p className={styles.ProductsEn_Contents}>
              At the forefront of cybersecurity, we offer top-tier vulnerability
              assessments and penetration testing services conducted by
              world-class security engineers. Our expertise spans a wide range
              of targets, from web applications to IoT devices. We are adept at
              addressing security concerns unique to modern technology stacks,
              including serverless architectures and APIs leveraging GraphQL.
              You have our trust to safeguard your digital assets with
              cutting-edge, comprehensive security solutions.
            </p>
            <Link to="/en#contact" className={styles.ProductsList_Link}>
              <Button buttonSize="Large">Request quote</Button>
            </Link>
          </li>
          <li className={styles.ProductsEn_List}>
            <img
              className={styles.ProductsEn_Image}
              src={data?.shisho?.publicURL || ""}
              alt="Shisho Cloud(シショウ クラウド)"
            />
            <h2 className={styles.ProductsEn_Heading}>
              Shisho Cloud: A Cloud AppSec Platform
            </h2>
            <p className={styles.ProductsEn_Contents}>
              Shisho Cloud automates the entire process of reviewing the
              security posture of web applications on the cloud, using
              continuous application scanning (DAST) and security posture
              reviews (CSPM and CIEM). Development and DevOps teams will be
              further empowered to work together in a self-service model
              designed for the scale and speed of their cloud development.
              Shisho Cloud is built by security experts with experiences
              totaling over 500 penetration testing projects, and is trusted by
              product security and AppSec teams to accurately reveal both
              external attack surfaces and the security posture of cloud assets.
            </p>
            <Link to="https://shisho.dev/" className={styles.ProductsList_Link}>
              <Button buttonTheme="Shisho" buttonSize="Large">
                Learn more
              </Button>
            </Link>
          </li>
        </ul>
      </div>
    );
  }
  return (
    <section>
      <div className={styles.ProductsList}>
        <SectionLayout>
          <div className={styles.ProductsList_GridWrapper}>
            <ul className={styles.ProductsList_Grid}>
              <li className={styles.ProductsList_Product}>
                <div>
                  <h3 className={styles.ProductsList_AssessmentName}>
                    セキュリティ診断
                  </h3>
                  <p className={styles.ProductsList_Description}>
                    ブラックボックス形式だけに頼らない独自の診断スタイル、高度な技術力、モダンな技術スタックへの対応、開発者目線の丁寧なレポーティングで他社が追随できない開発者体験を実現します。
                  </p>
                </div>
                <img
                  className={styles.ProductsList_AssessmentPeople}
                  src={data?.assessment_people?.publicURL || ""}
                  alt=""
                />
                <div className={styles.ProductsList_LinkWrapper}>
                  <Link to="/assessment" className={styles.ProductsList_Link}>
                    <Button
                      className={styles.ProductsList_Button}
                      buttonSize="Medium"
                    >
                      サービス詳細
                    </Button>
                  </Link>

                  <Link
                    to="/assessment/voice"
                    className={styles.ProductsList_Link}
                  >
                    <Button
                      className={styles.ProductsList_Button}
                      buttonTheme="Secondary"
                      buttonSize="Medium"
                    >
                      事例を見る
                    </Button>
                  </Link>
                </div>
              </li>
              <li className={styles.ProductsList_Product}>
                <div>
                  <h3>
                    <img
                      className={styles.ProductsList_KenroLogo}
                      src={data?.kenro_logo?.publicURL || ""}
                      alt="KENRO(ケンロー)"
                    />
                  </h3>
                  <p className={styles.ProductsList_Description}>
                    KENRO(ケンロー)は、全ての開発者が身につけるべきWeb開発におけるセキュリティ技術を豊富な実践演習で学ぶ、環境構築不要のクラウド型学習プラットフォームです。
                  </p>
                </div>
                <img
                  className={styles.ProductsList_KenroMockup}
                  src={data?.kenro_mockup?.publicURL || ""}
                  alt=""
                />
                <div className={styles.ProductsList_LinkWrapper}>
                  <Link to="/kenro" className={styles.ProductsList_Link}>
                    <Button
                      className={styles.ProductsList_Button}
                      buttonTheme="Kenro"
                      buttonSize="Medium"
                    >
                      サービス詳細
                    </Button>
                  </Link>
                  <Link to="/kenro/cases" className={styles.ProductsList_Link}>
                    <Button
                      className={styles.ProductsList_Button}
                      buttonTheme="Kenro_Reverse"
                      buttonSize="Medium"
                    >
                      事例を見る
                    </Button>
                  </Link>
                </div>
              </li>
            </ul>
          </div>
          <div className={styles.Shisho}>
            <div className={styles.ProductsList_Product}>
              <div className={styles.ShishoFlex}>
                <div>
                  <h3>
                    <img
                      className={styles.ShishoLogo}
                      src={data?.shisho_logo?.publicURL || ""}
                      alt="Shisho Cloud(シショウ クラウド)"
                    />
                  </h3>
                  <p className={styles.ShishoDescription}>
                    Shisho Cloud(シショウ
                    クラウド)は、脆弱性診断を誰でもまるごと内製化できるSaaSです。
                    WebアプリケーションやAWS等のクラウドを網羅的に診断し、継続的なリスク管理を実現します。
                  </p>
                  <div className={styles.ProductsList_LinkWrapper}>
                    <a
                      href="https://shisho.dev/ja"
                      className={styles.ProductsList_Link}
                    >
                      <Button
                        className={styles.ProductsList_Button}
                        buttonTheme="Shisho"
                        buttonSize="Medium"
                      >
                        サービス詳細
                      </Button>
                    </a>
                    <a
                      href="https://shisho.dev/ja/cases"
                      className={styles.ProductsList_Link}
                    >
                      <Button
                        className={styles.ProductsList_Button}
                        buttonTheme="Shisho_Reverse"
                        buttonSize="Medium"
                      >
                        事例を見る
                      </Button>
                    </a>
                  </div>
                </div>
                <div className={styles.ShishoMockWrapper}>
                  <img
                    className={styles.ShishoMockSp}
                    src={data?.shisho_mock_sp?.publicURL || ""}
                    alt=""
                  />
                  <img
                    className={styles.ShishoMockPc}
                    src={data?.shisho_mock_pc?.publicURL || ""}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </SectionLayout>
      </div>
    </section>
  );
};

export const Products: FC<ContainerProps> = (props) => {
  const data = useStaticQuery(graphql`
    query HomeProductsComponent {
      service: file(relativePath: { eq: "en/product_services.png" }) {
        publicURL
      }
      shisho: file(relativePath: { eq: "en/product_shisho.png" }) {
        publicURL
      }
      assessment: file(relativePath: { eq: "assessment.svg" }) {
        publicURL
      }
      solution_01: file(relativePath: { eq: "solution1.svg" }) {
        publicURL
      }
      solution_02: file(relativePath: { eq: "solution2.svg" }) {
        publicURL
      }
      solution_03: file(relativePath: { eq: "solution3.svg" }) {
        publicURL
      }
      assessment_people: file(
        relativePath: { eq: "top/assessment_people.png" }
      ) {
        publicURL
      }
      kenro_mockup: file(relativePath: { eq: "top/kenro_mockup.png" }) {
        publicURL
      }
      kenro_logo: file(relativePath: { eq: "kenro/logo_color.svg" }) {
        publicURL
      }
      shisho_logo: file(relativePath: { eq: "shisho/shisho_logo.svg" }) {
        publicURL
      }
      shisho_mock_sp: file(relativePath: { eq: "shisho/shisho_mock_sp.png" }) {
        publicURL
      }
      shisho_mock_pc: file(relativePath: { eq: "shisho/shisho_mock_pc.png" }) {
        publicURL
      }
    }
  `);
  return <Component {...props} data={data} />;
};
